import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import A from "./Basic/A";

const Header = styled.header`
  margin-bottom: 2em;

  @media (min-width: 720px) {
    margin-top: 5em;
    padding-right: 2em;
  }
`;

const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavItem = styled.li`
  margin-bottom: 0.5em;

  &::before {
    content: "=>";
    margin-right: 0.5em;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.font};
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.font};
  }
`;

export default () => (
  <Header>
    <Nav role="Menu">
      <NavItem role="Menuitem">
        <StyledLink to="/">Home</StyledLink>
      </NavItem>
      <NavItem role="Menuitem">
        <StyledLink to="/portfolio/">Portfolio</StyledLink>
      </NavItem>
      <NavItem role="Menuitem">
        <StyledLink to="/cv/">CV</StyledLink>
      </NavItem>
      <NavItem role="Menuitem">
        <StyledLink to="/publications/">Publications</StyledLink>
      </NavItem>
      <NavItem role="Menuitem">
        <A notobvious href="https://blog.martin.wudenka.de">
          Blog
        </A>
      </NavItem>
    </Nav>
  </Header>
);
