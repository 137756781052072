import React from "react";
import { H1 } from "../Basic/Headings";
import A from "../Basic/A";
import P from "../Basic/P";
import PortfolioItem from "./PortfolioItem";
import Scaffold from "../Scaffold";
import Carousel, { CarouselItem } from "../Basic/Carousel";
import styled from "styled-components";

import AdvokaturKunzScreenshot from "../img/advokatur-kunz-screenshot.jpg";
import CareSocialScreenshot from "../img/caresocial-screenshot.png";
import InowasScreenshot from "../img/inowas-screenshot.png";
import RobotImage from "../img/path-planning.png";
import HackaTUMScreenshot from "../img/hackatum-screenshot.jpg";
import ReversedConcept from "../img/reversed-concept.gif";
import Granite from "../img/granite-tracking.png";

const StyledCarousel = styled(Carousel)`
  margin-top: -0.5em;
`;

export default () => (
  <Scaffold maxWidth="75em">
    <H1>Portfolio</H1>
    <StyledCarousel>
    <CarouselItem>
        <PortfolioItem
          heading='Masters Thesis'
          img={Granite}
          imgAlt="Visualization of a visual odometry system."
        >
          <P>
            I conducted my Masters Thesis{" "}
            <i>
              Design and Implementation of an Odometry System for the ARDEA MAV Platform
            </i>{" "}
            at the German Aerospace Center (DLR) Institute of Robotics and Mechatronics (RM). For this I compared multiple state-of-the-art visual odometry systems and suggested several improvements to <A href="https://vision.in.tum.de/research/vslam/basalt">Basalt</A>. The resulting system showed improved robustness especially in extraterrestrial scenarios. The source code got published at <A href="https://github.com/DLR-RM/granite">github</A>. 
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading='Winner "start price" at Think.Make.Start 2019'
          img={ReversedConcept}
          imgAlt="Transformation of the drone to school experiments"
        >
          <P>
            At the two week event <i>Think.Make.Start</i> at UnternehmerTUM Munich we (a team of 5 interdiciplinary students) designed a drone that can be disassambled. The components can then be used to build awesome school experiment setups. The main goal is to provide school experimts with context to pupils. That way we hope pupils have an easier life connecting their knowledge to real world applications. See it in the Think.Make.Start <A href="https://www.thinkmakestart.com/reversed/">project archive</A>.
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading="Winner hackaTUM 2018"
          img={HackaTUMScreenshot}
          imgAlt="Screenshot of the backend dashboard of AID-cab"
        >
          <P>
            During the three days of the hackaTUM 2018 we took on the challenge
            of AID - Audi. For this we created a cab service, where you can
            catch a ride by just hailing towards an approaching car. When an
            already occupied cab notices a pedestrian that wants a ride another
            cab is notified and provided with the color profile of the
            pedestrians clothing. We setup a quick server infrastructure using
            docker, nodeJs and socket-io. The hailing detection was realised
            through PoseNet. I really enjoyed the challenge of setting up a
            prototype in such short time.{" "}
            <A href="https://devpost.com/software/aid-cab">
              You can see the project on devpost.
            </A>
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading="Bachelor Thesis"
          img={RobotImage}
          imgAlt="Screenshot of Robot performing path planning"
        >
          <P>
            For my bachelor thesis with the title{" "}
            <i>
              Design and Implementation of an Obstacle Avoidance Algorithm with
              ROS for a Mobile FPGA-based Robotic Platform
            </i>{" "}
            I implemented a robot controller with an Extended Kalman Filter as
            well as a path panning algorithm that uses ultra sonic depth
            meassurements. This resulted in more than 5000 lines of C++ code.
            Furthermore I learned how to present my ideas in scientific context.
            Here you see the simulation model of the four wheeled robot.
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading="INOWAS Webapp"
          img={InowasScreenshot}
          imgAlt="Screenshot of INOWAS Webapp"
        >
          <P>
            The INOWAS Research Group at TU Dresden aims to design a{" "}
            <A href="https://tu-dresden.de/bu/umwelt/hydro/inowas/research/fields/mar-web">
              web-based desicion support system
            </A>{" "}
            for groundwater related issues. When development started in 2017 I
            was in charge of the frontend. I set up an architecture utilizing
            react and redux. Thus, I learned hands on how to manage a very large
            codebase and stick to a professional work flow. The team lead
            described me the following: "He was noted for his precise workstyle
            and good programming expertise."
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading="Redesign CareSocial Webapp"
          img={CareSocialScreenshot}
          imgAlt="Screenshot of Caresocial Webapp"
        >
          <P>
            In 2016 I got to redesign the CareSocial Webapp in a modern fashion.
            CareSocial offers a convenient way for care services to manage their
            patient data, plan home visits and do balancing. Based on the design
            guidelines of Google and the ideas of the customer I developed a
            layout which I then realised in css. During this project I really
            improved my ability to organize my work flow over the long periode
            of more than 9 month.{" "}
            <A href="https://www.caresocial.de/download/bestellen/CareSocial-INFO.pdf">
              See it here!
            </A>
          </P>
        </PortfolioItem>
      </CarouselItem>
      <CarouselItem>
        <PortfolioItem
          heading="Homepage for Advokatur Kunz"
          img={AdvokaturKunzScreenshot}
          imgAlt="Screenshot of Website of the Advokatur Kunz"
        >
          <P>
            This was the first professional job I tackled. During my first
            semester of computer science I worked at a media agency. Based on an
            existing logo I designed a homepage for the Basel based lawyer
            Stefan Kunz. For this I defined a color scheme, first developed a
            prototype and then implemented it using php, html and css. I not
            only took care of a modern responsive layout, but also search engine
            optimization (SEO) and an e-mail form. This way I learned how to
            work in a structured manner and adapt to changing customer wishes.
          </P>
        </PortfolioItem>
      </CarouselItem>
    </StyledCarousel>
  </Scaffold>
);
