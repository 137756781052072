import React from "react";
import { H1 } from "../Basic/Headings";
import Scaffold from "../Scaffold";
import { Table, Tr, Th, Td } from "../Basic/Table"

export default () => (
  <Scaffold>
    <H1>CV</H1>
    <Table>
      <tbody>
        <Tr>
          <Th colSpan="2">Education</Th>
        </Tr>
        <Tr>
          <Td>10.2018 – 03.2021</Td>
          <Td>Master Robotics, Cognition and Intelligence, TU Munich (M.Sc. 1.5 <i>with distinction</i>)</Td>
        </Tr>
        <Tr>
          <Td>10.2015 - 09.2018</Td>
          <Td>Bachelor Computer Science, TU Dresden (B.Sc. 1.6)</Td>
        </Tr>
        <Tr>
          <Td>2006 - 2014</Td>
          <Td>Bernhard-von-Cotta-Gymnasium Brand-Erbisdorf (Abitur 1.6)</Td>
        </Tr>
        <Tr>
          <Th colSpan="2">Experience</Th>
        </Tr>
        <Tr>
          <Td>04.2021 – now</Td>
          <Td>
            software developer for autonomous driving at e:fs Techhub GmbH
          </Td>
        </Tr>
        <Tr>
          <Td>11.2018 – 03.2021</Td>
          <Td>
            working student at German Aerospace Center (DLR) Institute of
            Robotics and Mechatronics (RM)
          </Td>
        </Tr>
        <Tr>
          <Td>01.2017 – 10.2017</Td>
          <Td>frontend developer at INOWAS TU Dresden (working student)</Td>
        </Tr>
        <Tr>
          <Td>08.2015 – 10.2016</Td>
          <Td>webdesigner at eckblick GbR (working student)</Td>
        </Tr>
        <Tr>
          <Th colSpan="2">Skills</Th>
        </Tr>
        <Tr>
          <Td>Programming Languages</Td>
          <Td>C, C++, Python, JavaScript, Java, PHP, SQL</Td>
        </Tr>
        <Tr>
          <Td>Areas of Expertise</Td>
          <Td>web development (react), robotics (ROS), machine learning, Game Development (Unreal Engine)</Td>
        </Tr>
        <Tr>
          <Td>Languages</Td>
          <Td>German (native language), English (C1)</Td>
        </Tr>
      </tbody>
    </Table>
  </Scaffold>
);
