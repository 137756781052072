import styled from "styled-components";

export const H1 = styled.h1`
    color: ${props => props.theme.colors.font};
    font-size: 2em;
    margin: 0;
    padding-bottom: 1em;
    line-height: 1.5em;
`;

export const H2 = styled.h2`
    color: ${props => props.theme.colors.font};
    font-size: 1.5em;
    margin: 0;
    padding-bottom: 1em;
    line-height: 1em;
`;

export const H3 = styled.h3`
    color: ${props => props.theme.colors.font};
    font-size: 1.1em;
    margin: 0;
    padding-bottom: 0.5em;
    line-height: 1em;
`;