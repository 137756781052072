import React, { Component } from "react";
import "firacode/distr/fira_code.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import CV from "./pages/CV"
import Publications from "./pages/Publications"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />
  },
  {
    path: "/cv",
    element: <CV />
  },
  {
    path: "/publications",
    element: <Publications />
  }
]);

class App extends Component {
  render() {
    return <RouterProvider router={router} />;
  }
}

export default App;
