import styled from "styled-components";

export const Table = styled.table`
  margin-top: -1em;
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  text-align: left;
  font-size: 1.5em;
  font-weight: 700;

  ${Tr}:not(:first-child) & {
    padding-top: 1.5em;
  }
`;

export const Td = styled.td`
  vertical-align: top;
  &:nth-child(2) {
    width: 66%;
  }
`;