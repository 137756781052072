import React from "react";
// import Typing from "react-typing-animation";
import { H1 } from "../Basic/Headings";
import P from "../Basic/P";
import Portrait from "../img/portrait.jpg";
import styled from "styled-components";
import Scaffold from "../Scaffold";

const Img = styled.img`
  max-width: 100%;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  height: auto;
  filter: grayscale(100%);
  @media (min-width: 720px) {
    float: left;
    width: 33.3%;
    margin-right: 1em;
  }
`;

export default () => (
  <Scaffold>
    <Img src={Portrait} />
    <H1>Hi there!</H1>
    <P>
      My name is Martin and I'm a software developer for autonomous driving in Erlangen, Germany.
      I studied robotics, cognition and intelligence at the TU Munich and used to be a working student at the Institute of Robotics and Mechatronics (RM) of the German Aerospace Center (DLR).
      I'm heavily interested in machine learning (please don't call it AI) and like to tinker with mobile and flying robots.
      In my spare time I enjoy coding webapps.
      Besides that I love soccer, cross-country skiing and, of course, Calvin & Hobbes - Who doesn't, right?
    </P>
  </Scaffold>
);
