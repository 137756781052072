import React from "react";
import styled from "styled-components";
import "firacode/distr/fira_code.css";
import ComputerErrorImg from "./img/computer_error.svg";
import Color from "color";
import Header from "./Header";
import { SocialIcon } from "react-social-icons";

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.font};
  font-family: "Fira Code";
  font-size: 16px;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Container = styled.section`
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 1em;
  padding-right: 1em;
  max-width: ${props => props.maxWidth || "55em"};
  width: 100%;
  box-sizing: border-box;
  display: block;
  min-height: 50vh;
  margin-top: auto;
  margin-bottom: auto;

  @media (min-width: 720px) {
    display: flex;
    padding-left: 2em;
    padding-right: 2em;
  }
`;

const Footer = styled.footer`
  background-image: url(${ComputerErrorImg}),
    linear-gradient(
      to top,
      ${props =>
          Color(props.theme.colors.background)
            .darken(0.5)
            .string()}
        37%,
      transparent 10%
    );
  background-repeat: no-repeat;
  background-size: auto 25vh;
  background-position: 2em bottom, left bottom;
  height: 25vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1em;
`;

const Content = styled.article`
  flex: 1;
`;

const SocialIconStyled = styled(SocialIcon)`
  margin-left: 0.5em;
`;

export default ({ children, maxWidth }) => {
  return (
    <Wrapper>
      <Scroll>
        <Center>
          <Container maxWidth={maxWidth}>
            <Header />
            <Content>{children}</Content>
          </Container>
        </Center>
        <Footer>
          <SocialIconStyled url="https://www.linkedin.com/in/martin-wudenka" />
          <SocialIconStyled bgColor="#4078c0" url="https://github.com/hobbeshunter" />
          <SocialIconStyled url="https://gitlab.com/mwudenka" defaultSVG={{icon: 'M 0,0 H 64 V 64 H 0 Z', mask: 'M 0 0 L 0 64 L 64 64 L 64 0 L 0 0 z M 21.960938 16.591797 A 1.1663863 1.1663863 0 0 1 22.722656 16.837891 A 1.1430585 1.1430585 0 0 1 23.109375 17.427734 L 26.050781 26.423828 L 37.958984 26.423828 L 40.900391 17.427734 A 1.1663863 1.1663863 0 0 1 41.287109 16.839844 A 1.1663863 1.1663863 0 0 1 42.619141 16.769531 A 1.1347272 1.1347272 0 0 1 43.068359 17.308594 L 47.423828 28.675781 L 47.46875 28.791016 A 8.0913882 8.0913882 0 0 1 44.789062 38.128906 L 44.773438 38.142578 L 38.097656 43.140625 L 34.814453 45.625 L 32.814453 47.134766 A 1.3446767 1.3446767 0 0 1 31.189453 47.134766 L 29.189453 45.625 L 25.90625 43.140625 L 19.271484 38.171875 L 19.232422 38.142578 L 19.216797 38.132812 A 8.0880556 8.0880556 0 0 1 16.535156 28.783203 L 16.578125 28.669922 L 20.941406 17.306641 A 1.1447248 1.1447248 0 0 1 21.390625 16.767578 A 1.1663863 1.1663863 0 0 1 21.960938 16.591797 z ', color: '#FC6D27'}}/>
        </Footer>
      </Scroll>
    </Wrapper>
  );
};
