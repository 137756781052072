import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

const theme = {
  colors: {
    background: "#193549",
    font: "#efefef"
  }
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ThemeProvider theme={theme}>
  <App />
</ThemeProvider>);
