import React from "react";
import { H2 } from "../Basic/Headings";
import styled from "styled-components";

const Wrapper = styled.section``;

const Img = styled.img`
  max-width: 100%;
  width: auto;
  max-height: 80vh;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Text = styled.div`
margin-top: 1em;`;

export default ({ img, imgAlt, heading, children }) => (
  <Wrapper>
    <Img src={img} alt={imgAlt} />
    <Text>
      <H2>{heading}</H2>
      {children}
    </Text>
  </Wrapper>
);
