import React from "react";
import { H1, H3 } from "../Basic/Headings";
import Scaffold from "../Scaffold";
import { Table, Tr, Th, Td } from "../Basic/Table"
import A from "../Basic/A";
import P from "../Basic/P";

import GranitePaperIcon from "../img/granite-paper-icon.png";
import UrsimPaperIcon from "../img/ursim-paper-icon.png";
// import NoIcon from "../img/no-icon.png";

export default () => (
  <Scaffold>
    <H1>Publications</H1>
    <Table>
      <tbody>
        <Tr>
          <Th colSpan="2">2022</Th>
        </Tr>
        <Tr>
        <Td><img src={UrsimPaperIcon} alt="Icon of paper"/></Td>
          <Td>
            <H3>URSim - a Versatile Robot Simulator for Extra-Terrestrial Exploration</H3>
            <P>
                  (M Sewtz, H Lehner, Y Fanger, MW Wudenka, J Eberle, MG Müller, T Bodenmüller, M Schuster), In IEEE Aerospace Conference, 2022
            </P>
            <P>
              <A href="https://ieeexplore.ieee.org/document/9843576">IEEE Xplore</A>
            </P>
          </Td>
        </Tr>
        <Tr>
          <Th colSpan="2">2021</Th>
        </Tr>
        <Tr>
          <Td><img src={GranitePaperIcon} alt="Icon of paper"/></Td>
          <Td>
            <H3>Towards Robust Monocular Visual Odometry for Flying Robots on Planetary Missions</H3>
            <P>
                (MW Wudenka, MG Müller, N Demmel, A Wedler, R Triebel, D Cremers and W Stuerzl), In International Conference on Intelligent Robots and Systems (IROS), 2021
            </P>
            <P>
                <A href="https://arxiv.org/abs/2109.05509">arXiv</A> | <A href="https://ieeexplore.ieee.org/abstract/document/9636844">IEEE Xplore</A> | <A href="https://github.com/DLR-RM/granite">Code</A> | <A href="https://www.youtube.com/watch?v=TtoRU3TKv2s">Video</A>
            </P>
          </Td>
        </Tr>
      </tbody>
    </Table>
  </Scaffold>
);
