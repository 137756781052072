import React, { Component } from "react";
import styled from "styled-components";

const OuterWrapper = styled.div``;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height 0.5s ease;
`;

const Slider = styled.div`
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: ${props => -props.currentIdx * 100 + "%"};
  right: ${props => props.currentIdx * 100 + "%"};
  transition: left 0.5s ease, right 0.5s ease;
`;

const NextPrevNavigation = styled.div`
  display: block;
  display: flex;
`;

const NextPrevNavigator = styled.div`
  flex: 1;
  padding: 0.5em 0;
  font-size: 1.5em;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const NextNavigator = styled(NextPrevNavigator)`
  text-align: right;
`;

const PrevNavigator = styled(NextPrevNavigator)`
  text-align: left;
`;

export default class Carousel extends Component {
  state = {
    currentIdx: 0
  };

  items = [];

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  inc = () => {
    this.setState((oldState, props) => ({
      currentIdx: (oldState.currentIdx + 1) % props.children.length
    }));
  };

  dec = () => {
    this.setState((oldState, props) => ({
      currentIdx:
        (oldState.currentIdx - 1 + props.children.length) %
        props.children.length
    }));
  };

  setHeight = () => {
    if (this.wrapper && this.items[this.state.currentIdx]) {
      this.wrapper.current.style.height =
        this.items[this.state.currentIdx].clientHeight + "px";
    }
  };

  componentDidMount() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight);

    window.setTimeout(this.setHeight, 500);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeight);
  }

  componentDidUpdate() {
    this.setHeight();
  }

  render() {
    const { children, ...rest } = this.props;
    const { currentIdx } = this.state;

    const childrenWithProps = React.Children.map(children, (child, idx) => {
      const active = idx === currentIdx;
      return React.cloneElement(child, {
        active,
        childrenRef: e => {
          this.items[idx] = e;
        }
      });
    });

    return (
      <OuterWrapper {...rest}>
        <NextPrevNavigation>
          <PrevNavigator onClick={this.dec}>&lt;-</PrevNavigator>
          <NextNavigator onClick={this.inc}>-></NextNavigator>
        </NextPrevNavigation>

        <Wrapper ref={this.wrapper}>
          <Slider currentIdx={currentIdx}>{childrenWithProps}</Slider>
        </Wrapper>

        <NextPrevNavigation>
          <PrevNavigator onClick={this.dec}>&lt;-</PrevNavigator>
          <NextNavigator onClick={this.inc}>-></NextNavigator>
        </NextPrevNavigation>
      </OuterWrapper>
    );
  }
}

const ItemWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  flex: 1;
  visibility: ${props => (props.active ? "visible" : "hidden")};
  transition-property: visibility;
  transition-delay: ${props => (props.active ? "0" : "0.5s")};
`;

export const CarouselItem = ({ children, active, childrenRef }) => (
  <ItemWrapper ref={childrenRef} active={active}>
    {children}
  </ItemWrapper>
);
