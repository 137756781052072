import styled from "styled-components";

export default styled.a`
    color: ${props => props.theme.colors.font};
    text-decoration: ${props => !props.notobvious ? "underline" : "none"};
    cursor: pointer;

    &:hover {
        border-bottom: 1px solid ${props => props.theme.colors.font};
    }
`;